@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.hideMobile {
  @include breakpoint('lg', 'max') {
    display: none;
  }
}

.hideDesktop {
  @include breakpoint('lg', 'min') {
    display: none;
  }
}

.root {
  overflow: hidden;
  padding: map.get($spacers, 'spacing', '7') 0;
  position: relative;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
  }

  @include breakpoint('xl', 'min') {
    padding: map.get($spacers, 'spacing', '15') 0;
  }

  &[id] {
    scroll-margin-top: map.get($snaps, 'regular', 'mobile');

    @include breakpoint('lg', 'min') {
      scroll-margin-top: map.get($snaps, 'regular', 'desktop');
    }
  }

  &.full-width {
    [class*='Container_root__'] {
      max-width: 100%;
      padding: 0 0;
    }
  }

  &[class*='Block_mirage__'] {
    [class*='Spotlight_root__'],
    [class*='Block_disclaimer__'],
    [class*='Block_highlight__'],
    [class*='Block_editor__'],
    [class*='Block_title__'] {
      color: map.get($colors, 'white');
    }
  }

  &[class*='img-rounded'] {
    img {
      border-radius: map.get($radiuses, '3');
    }
  }

  &[class*='img-rounded-full'] {
    img {
      border-radius: 50%;
    }
  }

  &[class*='header-create-with-ai'] {
    padding-top: 0;
    padding-bottom: 0;

    [class*='Iframe_root'] {
      height: 1350px;
      padding-top: 0;

      iframe {
        border-radius: 0;
      }

      @include breakpoint('lg', 'min') {
        height: 900px;
      }
    }
  }
}

@each $background in map.get($configs, 'background') {
  .#{$background} {
    background-color: map.get($colors, $background);

    & + & {
      padding-top: 0;
    }
  }
}

.center {
  text-align: center;

  [class*='SignUpForm_inputWrapper__'],
  [class*='Field_inputWrapper__'] {
    text-align: left;
  }
}

.left {
  text-align: left;
}

.cover {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;

  & + * {
    position: relative;
  }
}

.title {
  @include spacing('md', 'bottom', 'margin');
}

.chip {
  @include spacing('md', 'bottom', 'margin');

  &:not(:first-child) {
    margin-left: map.get($spacers, 'spacing', '2');
  }
}

.disclaimer {
  @include spacing('md', 'bottom', 'margin');
}

.editor {
  @include spacing('md', 'bottom', 'margin');
}

.feature {
  @include spacing('md', 'bottom', 'margin');
}

.highlight {
  @include spacing('md', 'bottom', 'margin');

  &.center {
    display: inline-flex;
    text-align: center;
  }

  &.left {
    text-align: left;
  }
}

.links {
  .center & {
    ul {
      justify-content: center;
    }
  }

  .split & {
    ul {
      justify-content: flex-start;
    }
  }
}

.disableReverseOnMobile {
  @include breakpoint('lg', 'max') {
    & > div:first-child {
      flex-direction: column-reverse;
    }
  }
}

.logos {
  @include spacing('md', 'bottom', 'margin');
}

.marketo {
  @include spacing('md', 'bottom', 'margin');
}

.product {
  @include spacing('md', 'bottom', 'margin');
}

.quote {
  @include spacing('md', 'bottom', 'margin');
}

.table {
  @include spacing('md', 'bottom', 'margin');
}

.wpforms {
  @include spacing('md', 'bottom', 'margin');
}

.image {
  height: auto;

  .center &,
  .split & {
    margin-left: auto;
    margin-right: auto;
  }
}

.split {
  text-align: left;

  [class*='Block_quote__'] {
    border: 0;
  }

  .checks {
    margin-bottom: map.get($spacers, 'spacing', '4');

    ul {
      justify-content: flex-start;

      li {
        font-size: map.get($typography, 'size', '3');
      }
    }
  }
}

.embed {
  padding-top: 56.26%;
  position: relative;

  iframe {
    border: 0;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.box {
  text-align: left;
}

.section {
  @include spacing('lg', 'bottom', 'margin');
  @include spacing('lg', 'top', 'margin');
}

.expand {
  &:not(.section) {
    @include spacing('md', 'bottom', 'margin');
  }

  .center & {
    @include breakpoint('lg', 'min') {
      margin-left: -(map.get($spacers, 'spacing', '10') * 2);
      margin-right: -(map.get($spacers, 'spacing', '10') * 2);
    }

    @include breakpoint('xl', 'min') {
      margin-left: -(map.get($spacers, 'spacing', '12') * 2);
      margin-right: -(map.get($spacers, 'spacing', '12') * 2);
    }
  }
}

.overflow {
  overflow: visible;
}

.linkText {
  display: flex;
  gap: map.get($spacers, 'spacing', '3');
  justify-content: center;
  margin-top: map.get($spacers, 'spacing', '3');

  a {
    display: flex;
    align-items: center;
    font-size: map.get($typography, 'size', '2');

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: map.get($spacers, 'spacing', '1');
      text-underline-offset: map.get($spacers, 'spacing', '1');
    }
  }
}
